nav {
  display: flex !important;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  //background-color: rgba(0,0,0,0.6);
  background-color: #FFF;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.nav-container, .nav-container2 {
  align-items: center;
  //background-color: rgba(0,0,0,0.6);
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  width: 1440px;
}

.nav-container {
  /* width: 1440px;
  height: 57px; */
  justify-content: center;
  //width: 100%;
}

.burger-nav {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.burger-button {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 60;
  display: none;
  padding: 5px;
  border-radius: 2rem;
  background-color: var(--mainThemeColour)
}

.burger-layer {
  width: 1.2rem;
  height: 2px;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 3px;
}

.burger-layer:last-child{
  margin-bottom: 0;
}

.loged-in-content-container {
  /* position: absolute;
  top: 10px;
  left: 5%; */
  display: flex;
  flex-direction: row;
  align-self: center;
  //z-index: 55;
  //background-color: pink;
}

.loged-in-widget {
  margin-left: 10px;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.profile-pic-button img {
  width: 40px!important;
  height: 40px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.loged-in-widget img {
  width: 75%;
  height: 75%;
  object-fit: contain;
  object-position: center;
}

.chat-icon-box, .profiles-icon-box {
  position: relative;
  border: 2.5px solid var(--mainThemeColour);
  border-radius: 15%;
}

.profiles-icon-box {
  //border: 2.5px solid #FFF;
  //display: inline-block;
  //padding: 3px 0px 0px 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profiles-icon-box img {
  width: 10px;
  height: 10px;
  margin: 1px;
}

.chat-bubble {
  position: absolute;
  top: -10px;
  right: -20px;
  height: 30px;
  width: 30px;
}

.chat-bubble img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  object-position: center;
}

.nav-lish-logo img {
  width: 158px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}

.main-nav {
  list-style: none;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  margin: 0;
  color: #FFF;
  //float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-nav li {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  //padding: 8px 20px 8px 20px;
  padding: 0 20px 0px 0px;
  text-transform: uppercase;
  font-size: 80%;
  letter-spacing: 2px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: #21334B;
}

.main-nav li:last-child {
  border-right: 2px solid transparent;
  padding-right: 0;
}

.main-nav li a:link,
.main-nav li a:visited {
  text-decoration: none;
  //color: #FFF;
  color: #21334B;
  transition: border-bottom 0.2s, color 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 2px solid var(--mainThemeColour);
  color: var(--mainThemeColour);
}

.main-nav label:hover {
  cursor: pointer;
}

.nav-bar-sign-up-button {
  background-color: var(--mainThemeColour);
  color: #FFF;
  border-radius: 100px;
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-selector-container {
  display: flex;
  flex-direction: row;
  border: 2.5px solid var(--mainThemeColour);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: #21334B;
}

.language-selector-container label {
  font-size: 80%;
  font-family: 'Montserrat', sans-serif;
  //color: #FFF;
  margin: 0px 10px;
  text-transform: uppercase;
  text-align: center;
}

.language-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 2px 3px;
  border-radius: 6px;
  height: 15px;
}

.languageSelected {
  background-color: var(--mainThemeColour);
  color: #FFF;
}

@media (max-width: 1440px) {
  .nav-container {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .nav-container {
    width: 1024px;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    height: 0px;
  }

  .burger-button {
    display: flex;
  }

  .burger-nav,
  .burger-nav-show {
    flex-direction: column;
    //background-color: rgba(0,0,0,0.6);
    background-color: rgba(255,255,255,1);
    position: fixed;
    top:0;
    right:0px;
    height: 100vh;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 0px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
    width: 140px;
    justify-content: flex-start;
  }

  .burger-nav-show {
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
  }

  .main-nav {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    //padding-right: 25px;
    //padding-top: 130%;
    align-items: flex-start;
  }

  .main-nav li,
  .main-nav li a:link,
  .main-nav li a:visited {
    //color: #FFF;
  }

  .language-selector-container label {
    color: #21334B;
  }

  .main-nav li {
    padding-bottom: 20px;
  }

  .loged-in-content-container {
    flex-direction: column;
    top: 80px;
    //top: 10%;
    left: 30px;
  }

  .nav-lish-logo img {
    width: 90px;
    object-fit: contain;
    object-position: center;
    margin-left: 10px;
  }

  .loged-in-widget {
    margin: 10px 0 0 0;
  }

  .loged-in-widget img {
    width: 75%;
    height: 75%;
  }

  .chat-bubble img {
    width: 80%;
    height: 80%;
  }

  .profiles-icon-box img {
    width: 10px;
    height: 10px;
    margin: 1px;
  }

  .language-selector-container {
    width: 95px;
    color: #FFF;
  }

  .languageSelected label {
    //background-color: var(--mainThemeColour);
    color: #FFF;
  }

}

@media (max-width: 320px) {
  .loged-in-content-container {
    //top: 13%;
  }
}
