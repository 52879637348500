:root {
  --mainThemeColour: #5FC9AD;
  --fontColour: #2b333e;
  --blueCorporateColour: #3788D8;
  --subheadingGrey: #6b6b6b;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  //background: transparent;
}

::-webkit-scrollbar-track {
  //background-color: rgba(0,0,0,0);
  //background: rgba(0,0,0,0);
  border-radius: 20px;
  -webkit-box-shadow: none !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background: #b30000;
}

.row {
    max-width: 1140px;
    margin: 0 auto;
    align-content: center;
}

h2, h3, h4, h5, h6, p, label {
  font-family: 'Montserrat', sans-serif;
}

h2 {
  color: var(--fontColour);
  font-size: 300%;
  font-weight: 600;
}

h3, h4, p {
  font-size: 90%;
  font-weight: 200;
  word-spacing: 1px;
}

.header {
  display: flex;
  flex-direction: column;
  background-image: url(../Images/Home/TeachersGroupPhoto2.jpeg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 100vh;
  -webkit-background-attachment: fixed;
  background-attachment: scroll;
}

.hero-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* .burger-button {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 30;
  display: none;
  padding: 5px;
  border-radius: 2rem;
  background-color: var(--mainThemeColour)
} */

/* .burger-layer {
  width: 1.5rem;
  height: 0.25rem;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 5px;
}

.burger-layer:last-child{
  margin-bottom: 0;
} */

.profile-pic-button {
  /* width: 2rem;
  height: 2rem; */
  /* position: absolute;
  top: 15px;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 30; */
}

.hero-text-box {
  position: absolute;
  top: 15%;
  left:15%;
  display: flex;
  flex-direction: row;
}

.hero-text-box img {
  height: 184px;
  margin-bottom: 15px;
  object-fit: contain;
}

.hero-text-box-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 40px;
}

.hero-button {
  text-align: center;
}

.hero-text-box h1,
.hero-text-box h2{
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 450%;
  font-weight: 600;
  letter-spacing: 0;
}

.hero-text-box button {
  width: 100%;
}

button {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  background-color: var(--mainThemeColour);
  border: 2px solid var(--mainThemeColour);
  color: #FFF;
  padding: 10px 15px;
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0px;
  text-decoration: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

button:hover {
  background-color: rgba(0, 0, 0, 0);
  //border: 2px solid #FFF;
  color: #FFF;
}

.hero-text-box-buttons {
  margin-left: 20px;
}

.sign-in-button {
  margin-top: 15px;
  border-color: #FFF;
  background-color: transparent;
}

.sign-in-button:hover {
  background-color: var(--mainThemeColour);
  border-color: var(--mainThemeColour)
}

.section-heading, .white-section-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.section-bottom-border {
  border-bottom: 6px solid var(--mainThemeColour);
  width: 100px;
  margin-top: 15px;
}

.section-heading h2, .white-section-heading h2 {
  margin: 0;
  margin-top: 70px;
  margin-bottom: 0;
  font-size: 300%;
}

.section-heading h3, .white-section-heading h3 {
  color: var(--subheadingGrey);
  margin: 0 0 10px 0;
  font-size: 120% !important;
}

.white-section-heading h2 {
  margin-top: 35px;
  color: #FFF;
}

.white-section-heading h3 {
  color: #FFF;
}

.Teachers {
  background-color: white;
  text-align: left;
}

.PaymentSection {
  padding: 0 0;
  background-color: #FFF;
}

.teacherContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teacherContainer img {
  height: 273px;
  width: 273px;
  object-fit: cover;
  border-radius: 12px;
}

.teacherProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-color: none !important;
  outline-width: 0 !important;
}

.teacherProfile h3 {
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--fontColour)
}

.teacherProfile h4 {
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 3px;
  color: var(--subheadingGrey)
}

.OurStory {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #2b333e;
}

.LessonBundles {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.LessonBundles h3 {
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 0;
}

.LessonBundlesContainer{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

.LessonBundlesContainer img {
  width: 500px;
  height: 100%;
  margin: 0 20px 0 20px;
  margin-bottom: 50px;
  border-color: none !important;
  outline-width: 0 !important;
}

.Stories-Container{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.Stories-Container a {
  text-decoration: none;
}

.story-container {
  margin: 10px 0;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: none !important;
  outline-width: 0 !important;
}

.story-container img {
  width: 775px;
  height: 459px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 0;
}

.story-container h3 {
  margin: 0;
  margin-top: 10px;
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--fontColour);
}

.story-container h4 {
  margin: 0px;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 3px;
  color: var(--subheadingGrey);
}

.story-container h5 {
  margin: 0px;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  color: #FFF;
}

.LessonBundlesCard {
  width: 190px;
  height: 110px;
  margin-bottom: 50px;
  border-radius: 5px;
  background-color: #D3D7C9;
  padding-top: 10px;
  padding-left: 10px
}

.PaymentInfographicSection {
  background-color: #FFF;
}

.Section {
  background-color: #FFF;
}

.payment-infographic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
}

.payment-infographic img {
  padding-left: 15px;
}

.fourlessons {background-color: #80A095;}

.eightlessons {background-color: #5F7781;}

.twelvelessons {background-color: #335169;}

.LessonBundlesCard h3 {
  font-size: 120%;
  font-weight: 900;
  letter-spacing: 1px;
  margin-top: 0px;
  margin-bottom: 0;
}

.priceStyle h3 {
  display: inline-block;
  border-bottom: 2px solid var(--fontColour);
  font-weight: 300;
}

.lessongDurationText {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.lessongDurationText h4 {
  color: white;
  align-self: flex-end;
  font-size: 80%;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 40px;
}
