/* Big tablet from 1023px (widths smaller taht the 1140px row) */
@media (max-width: 1200px) {
  .hero-text-box h1 { font-size: 450%; }
  //button { font-size: 150%!important;}
  .payment-infographic img { width: 950px;}
  .LessonBundlesContainer img {
    width: 400px;
    height: 100%;
  }
}

/* Small tablet to big tablet: from 768px to 1023px */
@media (max-width: 1023px) {
  .row {max-width: 767px;}

  .hero-text-box {
    top:10%;
    left:10%;
  }

  .hero-text-box img {
    height: 90px;
    margin-bottom: 15px;
  }

  .hero-text-box button {
    width: auto;
    font-size: 80% !important;
  }

  .hero-text-box-buttons {
    margin-left: 10px;
  }

  .sign-in-button {
    margin-top: 10px;
  }

  /* .burger-button {
    display: flex;
  } */

  /* .burger-nav {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    right:0;
    height: 100vh;
    align-items: flex-start;
    padding-top: 40px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
  }

  .burger-nav-show {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    right:0;
    height: 100vh;
    align-items: flex-start;
    padding-top: 40px;
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
  } */

  .language-selector-container {
    flex-direction: column !important;
  }

  .language-selector-container label {
    font-size: 80%;
    margin: 10px 10px;
    width: 100%;
  }

  .language-label-container {
    padding: 1px 0;
    border-radius: 6px;
    width: 75%;
  }

  .burgerNavPadding {
    padding-top: 10%;
  }

  .main-nav li {
    border-right: 0px;
  }

  .hero-text-box h1 { font-size: 400%; }

  .teacherContainer {
    justify-content: center;
  }

  .teacherContainer img {
    height: 200px;
    width: 200px;
    border-radius: 12px;
  }

  .teacherProfile {
    margin: 0 20px;
  }

  .story-container img {
    width: 600px;
    height: auto;
  }

  .payment-infographic img {
    width: 650px;
    padding-left: 10px;
  }

  .LessonBundlesContainer img {
    width: 300px;
    height: 100%;
  }
}

/* Small phones to small tablets: from 481 to 767px */
@media (max-width: 767px) {

  .row {max-width: 480px;}

  .hero-text-box-buttons {
    margin-left: 10px;
  }

  .sign-in-button {
    margin-top: 5px;
  }

  button { font-size: 100%!important;}

  .hero-text-box h1 { font-size: 300%; }

  .section-heading h2 {
    font-size: 250%
  }

  .section-heading h3 {
    font-size: 100% !important;
  }

  .white-section-heading h2 {
    font-size: 250%
  }

  .white-section-heading h3 {
    font-size: 100% !important;
  }

  .teacherContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .LessonBundlesContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story-container img {
    width: 400px;
    height: 100%;
  }

  .payment-infographic img {
    width: 450px;
    height: 100%;
    padding-left: 5px;
  }
}

/*  Small phones: from 0 to 480px  */
@media (max-width: 480px) {
  .row {max-width: 480px;}
  .payment-infographic img { width: 400px;}

  .story-container img {
    width: 200px;
    height: 100%;
  }


}

@media (max-width: 414px) {
  .row {
    max-width: 375px;
  }

  .hero-text-box {
    left: 5%;
  }

  .hero-text-box img {
    height: 80px;
  }

  button {
    font-size: 70% !important;
    //padding: 5px 5px !important;
  }

  .burger-nav {
    //width: 180px;
  }

  .burger-nav-show {
    //width: 180px;
  }

  ul {
    padding-left: 10px;
  }

  .section-heading h2, .white-section-heading h2  {
    font-size: 180% !important;
  }

  .section-heading h3, .white-section-heading h3  {
    font-size: 90% !important;
  }

  .section-heading {
    margin-bottom: 15px;
  }

  .section-bottom-border {
    margin-top: 0;
  }

  .story-container {
    margin-top: 0;
  }

  .story-container img {
    width: 250px;
    height: auto;
  }

  .Stories-Container{
    margin-bottom: 0;
  }

  .payment-infographic img { width: 300px;}

  .LessonBundlesContainer img {
    width: 240px;
    height: 100%;
  }
}

@media (max-width: 320px) {
  .row {
    max-width: 320px;
  }

  .hero-text-box {
    left: 5%;
  }

  .hero-text-box img {
    height: 60px;
    margin-bottom: 10px;
  }

  button {
    font-size: 40% !important;
    //padding: 5px 2px !important;
  }

  ul {
    padding-left: 10px;
  }

  .section-heading h2 {
    font-size: 100%;
  }

  .story-container img {
    width: 250px;
    height: auto;
  }

  .payment-infographic img {
    width: 280px;
    padding-left: 5px;
  }

  .LessonBundlesContainer img {
    width: 200px;
    height: 100%;
  }
}

@media (max-width: 280px) {
  .teacherContainer img {
    height: 150px;
    width: 150px;
    border-radius: 8px;
  }

  .story-container img {
    width: 160px;
    height: auto;
    border-radius: 5px;
  }

  .LessonBundlesContainer img {
    width: 150px;
    height: 100%;
  }
}
