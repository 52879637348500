.video-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #43cea2; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #43cea2, #185a9d); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #43cea2, #185a9d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.videos {
  background-color: #000;
  display: flex;
  flex-direction: row;
}

.logo-container {
  position: absolute;
  left:30px;
  top:30px;
  z-index: 20;
}

.logo-container img {
  height: 50px;
}

.right-content-container, .show-right-content-container {
  width: 25%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #e9e9e9;
  z-index: 20;
}

.timer, .show-timer {
  margin: 15px 0px 0 0;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 10%;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  //padding: 10px 20px;
  border-radius: 10px;
}

.timer-contents-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 90%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 10px;
}

.show-chat-button {
  display: none;
}

.chat-container, .show-chat-container {
  margin: 10px 0px 0 0;
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat-message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  background-color: #FFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: scroll;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
}

.last-chat-item {
  height: 100px;
  width: 100%;
  //background-color: pink;
}

.chat-message, .my-chat-message {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 5px;
}

.my-chat-message {
  align-items: flex-end;
  flex-direction:
}

.chat-message-name {
  margin-left: 40px;
  margin-bottom: 2px;
}

.chat-message img, .empty-profilePic {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.chat-message label {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 70%;
}

.chat-profil-pic-and-text-container, .my-chat-content {
  display: flex;
  flex-direction: row;
}

.my-chat-content {
  flex-direction: row-reverse;
}

.chat-message-text-container {
  display: flex;
  flex-direction: column;
  background-color: #e9e9e9;
  margin-left: 10px;
  justify-content: center;
  border-radius: 10px;
  border-top-left-radius: 0;
  height: auto;
  max-width: 70%;
  padding: 5px 0;
  word-wrap: break-word;
}

.chat-message-text-container label {
  margin: 0 10px;
  word-break: break-word;
}

.my-text-container {
  background-color: #599cc9;
  margin-right: 10px;
  margin-left: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  max-width: 70%;
}

.my-text-container label {
  color: #FFF;
  word-break: break-word;
}

.chat-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #FFF;
  padding: 5px 5px 0 5px;
  border-top: 2px solid #e9e9e9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input label {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 50%;
  margin-top: 5px;
}

.chat-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.send-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--mainThemeColour);
}

.send-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.chat-container input, .show-chat-container input, .chat-container textarea, .show-chat-container textarea {
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  width: 80%;
  height: 40px;
  resize: none;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 5px;
}

.chat-container input:focus-visible, .show-chat-container input:focus-visible, .show-chat-container textarea:focus-visible {
  outline: none;
}

.chat-container input:focus, .show-chat-container input:focus {
  outline: none;
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  //background-color: pink;
}

.empty-chat-container h3, .empty-chat-container h4 {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 75%;
  font-weight: 300;
  border: 1px solid var(--fontColour);
  padding: 2px 10px;
  border-radius: 20px;
}

.empty-chat-container h4 {
  background-color: var(--mainThemeColour);
  color: #FFF;
  border: 1px solid var(--mainThemeColour);
  border-radius: 10px;
  padding: 10px 10px;
  font-weight: 500;
}

.profilePic-clock-container label, .profilePic-clock-container h4, .count-down label {
  color: var(--subheadingGrey);
  font-size: 90%;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.profilePic-clock-container h4, .count-down label {
  color: var(--fontColour);
  font-weight: 600;
}

.profilePic-clock-container{
 display: flex;
 flex-direction: row;
 height: auto;
}

.profilePic-clock-container img, .count-down {
  width: 60px;
  height: 60px;
}

.profilePic-clock-container img {
  border-radius: 10px;
  margin-right: 10px;
}

.clock-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.clock-container h4 {
  margin-top: 0;
}

.count-down {
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  z-index: 10;
}

.redTimer{
  border: 2px solid red;
}

.redTimer label { color: red;}

.remote-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 75%;
  background-color: black;
  position: relative;
}

.remote-video video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.my-video, .lower-my-video {
  position: absolute;
  bottom: 0%;
  right: 0;
  width: 200px;
  height:200px;
  z-index: 15;
}

.my-video video, .lower-my-video video {
  object-fit: cover;
}

.video-buttons, .hide-video-buttons, .green-video-buttons {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 30px;
  //bottom: 5%;
  //width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-buttons button, .hide-video-buttons button {
  margin: 20px;
  padding: 10px 30px;
  width: 200px;
}

.video-buttons img, .hide-video-buttons img {
  margin: 20px;
  height: 50px;
  width: 50px;
}

.green-video-buttons {
  position: absolute;
  flex-direction: row;
  left: 0;
  bottom: 5%;
  width: 100%;
  height: auto;
}

.waiting-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  background-color: #000;
}

.waiting-text h2 {
  color: #FFF;
  text-align: center;
}

.video-call-error-button-container, .hide-error-button  {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 80%;
}

.video-call-error-button-container button, .hide-error-button button {
  background-color: #D14335;
  border-color: #D14335;
  border-radius: 12px;
}

.video-call-error-button-container button:hover, .hide-error-button button:hover {
  background-color: rgba(0,0,0,0);
  color: #D14335;
}

.error-log-input {
  //background-color: pink;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-log-input textarea {
  margin: 5px 0px;
  width: 97%;
}
