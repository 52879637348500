body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
font-family: "Tahu";
src: local("Tahu"),
 url(/static/media/Tahu.e4f33516.ttf) format("truetype");
font-weight: bold;
font-display: swap;
}

@font-face {
  font-family: "Mplus 1p-Medium";
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/MPLUS1p-Medium.d945ded2.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Mplus 1p-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url(/static/media/MPLUS1p-ExtraBold.dd6dd30e.ttf) format("truetype");
  font-display: swap;
}

.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.landing-page-hero-section {
  background-color: #000;
  width: 1440px;
  height: 744px;
  /* background-image: url(../Images/LandingPage/HeroHeader.jpeg);
  background-position-x: 50%;
  background-position-y: 80%;
  background-size: 130%; */
  display: flex;
  flex-direction: column;
  //align-items: center;
  position: relative;
  justify-content: flex-end;
  background-color: #000;
  overflow-x: hidden;
}

.landing-page-hero-background-image {
  /* background-position-x: 50%;
  background-position-y: 80%;
  background-size: 130%; */
  object-position: center;
  object-fit: cover;
  width: 1440px;
  height: 744px;
  position: absolute;
  top:0;
  left: 0;
  z-index: 0;
}

.landing-page-hero-section-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  //padding-left: 70px;
  //margin-top: 127px;
  z-index: 5;
}

.landing-page-hero-section-button-container img,
.lish-logo-image {
  width: 158px;
  height: 104px;
}

.landing-page-hero-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page-hero-section-button-container  button {
  /* width: 158px;
  height: 40px; */
  /* width: 516.3px;
  height: 109px; */
  width: 350px;
  height: 60px;
  margin-top: 10px;
  padding: 0;
  /* font-size: 20px;
  font-style: normal;
  font-weight: 500; */
  font-family: "Mplus 1p-Medium";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 23px;
  letter-spacing: 0.08em;
}

.landing-page-hero-section-text-container {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 251px;
  z-index: 1;
}

.landing-page-hero-section h2 {
  color: #FFF;
  font-family: "Mplus 1p-Medium";
  font-size: 56.43px;
}

@media (max-width: 1440px) {
  .landing-page-hero-section {
    width: 1280px;
    background-position-x: 50%;
    background-position-y: 80%;
    background-size: 130%;
  }

  .landing-page-hero-background-image {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .landing-page-hero-section {
    width: 1024px;
    height: 598px;
    background-position-x: 55%;
    background-position-y: 70%;
    background-size: 145%;
  }

  .landing-page-hero-background-image {
    width: 1024px;
    height: 598px;
  }

  .landing-page-hero-section-button-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .landing-page-hero-buttons {
    margin-left: 15px;
    margin-top: -10px;
  }

  .landing-page-hero-buttons button {
    width: 207px;
    height: 60px;
    font-size: 18px;
  }

  .landing-page-hero-section-text-container {
    height: 122px;
    //margin-top: 191px;
  }

  .landing-page-hero-section h2 {
    font-size: 45.14px;
  }
}

@media (max-width: 1023px) {
  .landing-page-hero-section {
    width: 1023px;
    height: 598px;
    background-position-x: 60%;
    background-size: 120%;
  }

  .landing-page-hero-background-image {
    width: 1023px;
    height: 598px;
  }

  .landing-page-hero-section-button-container {
    margin-top: 64px;
  }

  .landing-page-hero-section-text-container {
    height: 99px;
    //margin-top: 305px;
  }

  .landing-page-hero-section h2 {
    font-size: 35px;
  }
}

@media (max-width: 786px) {
  .landing-page-hero-section {
    width: 786px;
    height: 427px;
    background-position:center;
    background-size: contain;
    //position: relative;
  }

  .landing-page-hero-background-image {
    width: 786px;
    height: 427px;
  }

  .landing-page-hero-section-button-container {
    /* margin-top: 64px;
    flex-direction: column; */
    margin-bottom: 50px;
  }

  .landing-page-hero-buttons {
    flex-direction: row;
    margin-top: 140px;
    margin-left: 0;
  }

  .landing-page-hero-section-button-container  img {
    width: 130px;
    height: 86px;
  }

  .landing-page-hero-section-button-container  button {
    width: 174.42px;
    height: 50.56px;
    font-size: 15.1667px;
    margin: 15px;
    z-index: 10;
  }

  .landing-page-hero-section-text-container {
    height: 151px;
    margin-top: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    align-items: flex-end;
    //padding-bottom: 30px;
    position: absolute;
    bottom: 0;
  }

  .landing-page-hero-section h2 {
    font-size: 19px;
  }
}

@media (max-width: 428px) {
  .landing-page-hero-section {
    width: 428px;
    height: 364px;
    background-position:center;
    background-size: contain;
  }

  .landing-page-hero-background-image {
    width: 428px;
    height: 364px;
  }

  .landing-page-hero-section-button-container {
    margin-top: 50px;
  }

  .landing-page-hero-section-button-container  img,
  .lish-logo-image {
    width: 101px;
    height: 67px;
  }

  .landing-page-hero-buttons {
    flex-direction: column;
    margin-top: 90px;
    margin-left: 0;
  }

  .landing-page-hero-section-button-container  button {
    width: 155.94px;
    height: 45.2px;
    font-size: 13.5603px;
    margin: 5px;
  }

  .landing-page-hero-section-text-container {
    height: 135px;
    padding-bottom: 15px;
  }

  .landing-page-hero-section h2 {
    font-size: 15px !important;
  }
}

/* Big tablet from 1023px (widths smaller taht the 1140px row) */
@media (max-width: 1200px) {
  .hero-text-box h1 { font-size: 450%; }
  //button { font-size: 150%!important;}
  .payment-infographic img { width: 950px;}
  .LessonBundlesContainer img {
    width: 400px;
    height: 100%;
  }
}

/* Small tablet to big tablet: from 768px to 1023px */
@media (max-width: 1023px) {
  .row {max-width: 767px;}

  .hero-text-box {
    top:10%;
    left:10%;
  }

  .hero-text-box img {
    height: 90px;
    margin-bottom: 15px;
  }

  .hero-text-box button {
    width: auto;
    font-size: 80% !important;
  }

  .hero-text-box-buttons {
    margin-left: 10px;
  }

  .sign-in-button {
    margin-top: 10px;
  }

  /* .burger-button {
    display: flex;
  } */

  /* .burger-nav {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    right:0;
    height: 100vh;
    align-items: flex-start;
    padding-top: 40px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
  }

  .burger-nav-show {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    right:0;
    height: 100vh;
    align-items: flex-start;
    padding-top: 40px;
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
    z-index: 50;
  } */

  .language-selector-container {
    flex-direction: column !important;
  }

  .language-selector-container label {
    font-size: 80%;
    margin: 10px 10px;
    width: 100%;
  }

  .language-label-container {
    padding: 1px 0;
    border-radius: 6px;
    width: 75%;
  }

  .burgerNavPadding {
    padding-top: 10%;
  }

  .main-nav li {
    border-right: 0px;
  }

  .hero-text-box h1 { font-size: 400%; }

  .teacherContainer {
    justify-content: center;
  }

  .teacherContainer img {
    height: 200px;
    width: 200px;
    border-radius: 12px;
  }

  .teacherProfile {
    margin: 0 20px;
  }

  .story-container img {
    width: 600px;
    height: auto;
  }

  .payment-infographic img {
    width: 650px;
    padding-left: 10px;
  }

  .LessonBundlesContainer img {
    width: 300px;
    height: 100%;
  }
}

/* Small phones to small tablets: from 481 to 767px */
@media (max-width: 767px) {

  .row {max-width: 480px;}

  .hero-text-box-buttons {
    margin-left: 10px;
  }

  .sign-in-button {
    margin-top: 5px;
  }

  button { font-size: 100%!important;}

  .hero-text-box h1 { font-size: 300%; }

  .section-heading h2 {
    font-size: 250%
  }

  .section-heading h3 {
    font-size: 100% !important;
  }

  .white-section-heading h2 {
    font-size: 250%
  }

  .white-section-heading h3 {
    font-size: 100% !important;
  }

  .teacherContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .LessonBundlesContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story-container img {
    width: 400px;
    height: 100%;
  }

  .payment-infographic img {
    width: 450px;
    height: 100%;
    padding-left: 5px;
  }
}

/*  Small phones: from 0 to 480px  */
@media (max-width: 480px) {
  .row {max-width: 480px;}
  .payment-infographic img { width: 400px;}

  .story-container img {
    width: 200px;
    height: 100%;
  }


}

@media (max-width: 414px) {
  .row {
    max-width: 375px;
  }

  .hero-text-box {
    left: 5%;
  }

  .hero-text-box img {
    height: 80px;
  }

  button {
    font-size: 70% !important;
    //padding: 5px 5px !important;
  }

  .burger-nav {
    //width: 180px;
  }

  .burger-nav-show {
    //width: 180px;
  }

  ul {
    padding-left: 10px;
  }

  .section-heading h2, .white-section-heading h2  {
    font-size: 180% !important;
  }

  .section-heading h3, .white-section-heading h3  {
    font-size: 90% !important;
  }

  .section-heading {
    margin-bottom: 15px;
  }

  .section-bottom-border {
    margin-top: 0;
  }

  .story-container {
    margin-top: 0;
  }

  .story-container img {
    width: 250px;
    height: auto;
  }

  .Stories-Container{
    margin-bottom: 0;
  }

  .payment-infographic img { width: 300px;}

  .LessonBundlesContainer img {
    width: 240px;
    height: 100%;
  }
}

@media (max-width: 320px) {
  .row {
    max-width: 320px;
  }

  .hero-text-box {
    left: 5%;
  }

  .hero-text-box img {
    height: 60px;
    margin-bottom: 10px;
  }

  button {
    font-size: 40% !important;
    //padding: 5px 2px !important;
  }

  ul {
    padding-left: 10px;
  }

  .section-heading h2 {
    font-size: 100%;
  }

  .story-container img {
    width: 250px;
    height: auto;
  }

  .payment-infographic img {
    width: 280px;
    padding-left: 5px;
  }

  .LessonBundlesContainer img {
    width: 200px;
    height: 100%;
  }
}

@media (max-width: 280px) {
  .teacherContainer img {
    height: 150px;
    width: 150px;
    border-radius: 8px;
  }

  .story-container img {
    width: 160px;
    height: auto;
    border-radius: 5px;
  }

  .LessonBundlesContainer img {
    width: 150px;
    height: 100%;
  }
}

nav {
  display: flex !important;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  //background-color: rgba(0,0,0,0.6);
  background-color: #FFF;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.nav-container, .nav-container2 {
  align-items: center;
  //background-color: rgba(0,0,0,0.6);
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  width: 1440px;
}

.nav-container {
  /* width: 1440px;
  height: 57px; */
  justify-content: center;
  //width: 100%;
}

.burger-nav {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.burger-button {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 60;
  display: none;
  padding: 5px;
  border-radius: 2rem;
  background-color: var(--mainThemeColour)
}

.burger-layer {
  width: 1.2rem;
  height: 2px;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 3px;
}

.burger-layer:last-child{
  margin-bottom: 0;
}

.loged-in-content-container {
  /* position: absolute;
  top: 10px;
  left: 5%; */
  display: flex;
  flex-direction: row;
  align-self: center;
  //z-index: 55;
  //background-color: pink;
}

.loged-in-widget {
  margin-left: 10px;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.profile-pic-button img {
  width: 40px!important;
  height: 40px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.loged-in-widget img {
  width: 75%;
  height: 75%;
  object-fit: contain;
  object-position: center;
}

.chat-icon-box, .profiles-icon-box {
  position: relative;
  border: 2.5px solid var(--mainThemeColour);
  border-radius: 15%;
}

.profiles-icon-box {
  //border: 2.5px solid #FFF;
  //display: inline-block;
  //padding: 3px 0px 0px 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profiles-icon-box img {
  width: 10px;
  height: 10px;
  margin: 1px;
}

.chat-bubble {
  position: absolute;
  top: -10px;
  right: -20px;
  height: 30px;
  width: 30px;
}

.chat-bubble img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  object-position: center;
}

.nav-lish-logo img {
  width: 158px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}

.main-nav {
  list-style: none;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  margin: 0;
  color: #FFF;
  //float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-nav li {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  //padding: 8px 20px 8px 20px;
  padding: 0 20px 0px 0px;
  text-transform: uppercase;
  font-size: 80%;
  letter-spacing: 2px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: #21334B;
}

.main-nav li:last-child {
  border-right: 2px solid transparent;
  padding-right: 0;
}

.main-nav li a:link,
.main-nav li a:visited {
  text-decoration: none;
  //color: #FFF;
  color: #21334B;
  transition: border-bottom 0.2s, color 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 2px solid var(--mainThemeColour);
  color: var(--mainThemeColour);
}

.main-nav label:hover {
  cursor: pointer;
}

.nav-bar-sign-up-button {
  background-color: var(--mainThemeColour);
  color: #FFF;
  border-radius: 100px;
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-selector-container {
  display: flex;
  flex-direction: row;
  border: 2.5px solid var(--mainThemeColour);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: #21334B;
}

.language-selector-container label {
  font-size: 80%;
  font-family: 'Montserrat', sans-serif;
  //color: #FFF;
  margin: 0px 10px;
  text-transform: uppercase;
  text-align: center;
}

.language-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 2px 3px;
  border-radius: 6px;
  height: 15px;
}

.languageSelected {
  background-color: var(--mainThemeColour);
  color: #FFF;
}

@media (max-width: 1440px) {
  .nav-container {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .nav-container {
    width: 1024px;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    height: 0px;
  }

  .burger-button {
    display: flex;
  }

  .burger-nav,
  .burger-nav-show {
    flex-direction: column;
    //background-color: rgba(0,0,0,0.6);
    background-color: rgba(255,255,255,1);
    position: fixed;
    top:0;
    right:0px;
    height: 100vh;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 0px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    z-index: 50;
    width: 140px;
    justify-content: flex-start;
  }

  .burger-nav-show {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

  .main-nav {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    //padding-right: 25px;
    //padding-top: 130%;
    align-items: flex-start;
  }

  .main-nav li,
  .main-nav li a:link,
  .main-nav li a:visited {
    //color: #FFF;
  }

  .language-selector-container label {
    color: #21334B;
  }

  .main-nav li {
    padding-bottom: 20px;
  }

  .loged-in-content-container {
    flex-direction: column;
    top: 80px;
    //top: 10%;
    left: 30px;
  }

  .nav-lish-logo img {
    width: 90px;
    object-fit: contain;
    object-position: center;
    margin-left: 10px;
  }

  .loged-in-widget {
    margin: 10px 0 0 0;
  }

  .loged-in-widget img {
    width: 75%;
    height: 75%;
  }

  .chat-bubble img {
    width: 80%;
    height: 80%;
  }

  .profiles-icon-box img {
    width: 10px;
    height: 10px;
    margin: 1px;
  }

  .language-selector-container {
    width: 95px;
    color: #FFF;
  }

  .languageSelected label {
    //background-color: var(--mainThemeColour);
    color: #FFF;
  }

}

@media (max-width: 320px) {
  .loged-in-content-container {
    //top: 13%;
  }
}

:root {
  --mainThemeColour: #5FC9AD;
  --fontColour: #2b333e;
  --blueCorporateColour: #3788D8;
  --subheadingGrey: #6b6b6b;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  //background: transparent;
}

::-webkit-scrollbar-track {
  //background-color: rgba(0,0,0,0);
  //background: rgba(0,0,0,0);
  border-radius: 20px;
  -webkit-box-shadow: none !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background: #b30000;
}

.row {
    max-width: 1140px;
    margin: 0 auto;
    align-content: center;
}

h2, h3, h4, h5, h6, p, label {
  font-family: 'Montserrat', sans-serif;
}

h2 {
  color: #2b333e;
  color: var(--fontColour);
  font-size: 300%;
  font-weight: 600;
}

h3, h4, p {
  font-size: 90%;
  font-weight: 200;
  word-spacing: 1px;
}

.header {
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/TeachersGroupPhoto2.e9935549.jpeg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  -webkit-background-attachment: fixed;
  background-attachment: scroll;
}

.hero-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* .burger-button {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 30;
  display: none;
  padding: 5px;
  border-radius: 2rem;
  background-color: var(--mainThemeColour)
} */

/* .burger-layer {
  width: 1.5rem;
  height: 0.25rem;
  background-color: #FFF;
  border-radius: 10px;
  margin-bottom: 5px;
}

.burger-layer:last-child{
  margin-bottom: 0;
} */

.profile-pic-button {
  /* width: 2rem;
  height: 2rem; */
  /* position: absolute;
  top: 15px;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 30; */
}

.hero-text-box {
  position: absolute;
  top: 15%;
  left:15%;
  display: flex;
  flex-direction: row;
}

.hero-text-box img {
  height: 184px;
  margin-bottom: 15px;
  object-fit: contain;
}

.hero-text-box-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 40px;
}

.hero-button {
  text-align: center;
}

.hero-text-box h1,
.hero-text-box h2{
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 450%;
  font-weight: 600;
  letter-spacing: 0;
}

.hero-text-box button {
  width: 100%;
}

button {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  background-color: #5FC9AD;
  background-color: var(--mainThemeColour);
  border: 2px solid #5FC9AD;
  border: 2px solid var(--mainThemeColour);
  color: #FFF;
  padding: 10px 15px;
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0px;
  text-decoration: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

button:hover {
  background-color: rgba(0, 0, 0, 0);
  //border: 2px solid #FFF;
  color: #FFF;
}

.hero-text-box-buttons {
  margin-left: 20px;
}

.sign-in-button {
  margin-top: 15px;
  border-color: #FFF;
  background-color: transparent;
}

.sign-in-button:hover {
  background-color: #5FC9AD;
  background-color: var(--mainThemeColour);
  border-color: #5FC9AD;
  border-color: var(--mainThemeColour)
}

.section-heading, .white-section-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.section-bottom-border {
  border-bottom: 6px solid #5FC9AD;
  border-bottom: 6px solid var(--mainThemeColour);
  width: 100px;
  margin-top: 15px;
}

.section-heading h2, .white-section-heading h2 {
  margin: 0;
  margin-top: 70px;
  margin-bottom: 0;
  font-size: 300%;
}

.section-heading h3, .white-section-heading h3 {
  color: #6b6b6b;
  color: var(--subheadingGrey);
  margin: 0 0 10px 0;
  font-size: 120% !important;
}

.white-section-heading h2 {
  margin-top: 35px;
  color: #FFF;
}

.white-section-heading h3 {
  color: #FFF;
}

.Teachers {
  background-color: white;
  text-align: left;
}

.PaymentSection {
  padding: 0 0;
  background-color: #FFF;
}

.teacherContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teacherContainer img {
  height: 273px;
  width: 273px;
  object-fit: cover;
  border-radius: 12px;
}

.teacherProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-color: none !important;
  outline-width: 0 !important;
}

.teacherProfile h3 {
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #2b333e;
  color: var(--fontColour)
}

.teacherProfile h4 {
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 3px;
  color: #6b6b6b;
  color: var(--subheadingGrey)
}

.OurStory {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #2b333e;
}

.LessonBundles {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.LessonBundles h3 {
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 0;
}

.LessonBundlesContainer{
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
}

.LessonBundlesContainer img {
  width: 500px;
  height: 100%;
  margin: 0 20px 0 20px;
  margin-bottom: 50px;
  border-color: none !important;
  outline-width: 0 !important;
}

.Stories-Container{
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.Stories-Container a {
  text-decoration: none;
}

.story-container {
  margin: 10px 0;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: none !important;
  outline-width: 0 !important;
}

.story-container img {
  width: 775px;
  height: 459px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 0;
}

.story-container h3 {
  margin: 0;
  margin-top: 10px;
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2b333e;
  color: var(--fontColour);
}

.story-container h4 {
  margin: 0px;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 3px;
  color: #6b6b6b;
  color: var(--subheadingGrey);
}

.story-container h5 {
  margin: 0px;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  color: #FFF;
}

.LessonBundlesCard {
  width: 190px;
  height: 110px;
  margin-bottom: 50px;
  border-radius: 5px;
  background-color: #D3D7C9;
  padding-top: 10px;
  padding-left: 10px
}

.PaymentInfographicSection {
  background-color: #FFF;
}

.Section {
  background-color: #FFF;
}

.payment-infographic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
}

.payment-infographic img {
  padding-left: 15px;
}

.fourlessons {background-color: #80A095;}

.eightlessons {background-color: #5F7781;}

.twelvelessons {background-color: #335169;}

.LessonBundlesCard h3 {
  font-size: 120%;
  font-weight: 900;
  letter-spacing: 1px;
  margin-top: 0px;
  margin-bottom: 0;
}

.priceStyle h3 {
  display: inline-block;
  border-bottom: 2px solid #2b333e;
  border-bottom: 2px solid var(--fontColour);
  font-weight: 300;
}

.lessongDurationText {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
}

.lessongDurationText h4 {
  color: white;
  align-self: flex-end;
  font-size: 80%;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 40px;
}

.show-chat-list, .hide-chat-list {
  position: absolute;
  top: 100px;
  //left: 0%;
  width: 300px;
  max-height: 400px;
  border-radius: 5px;
  //background-color: rgba(0,0,0,0.6);
  background-color: rgba(255,255,255,1);
  //background-color: #FFF;
  transition: top 0.3s ease-in-out;
  overflow: scroll;
  overflow-x: hidden;
}

.hide-chat-list {
  top: -600px;
  transition: top 0.3s ease-in-out;
}

.empty-chat-list {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
}

.empty-chat-list label {
  margin: 10px 0;
}

.chat-icon {
  font-size: 350%;
}

.chat-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin: 20px 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-list-item img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.chat-list-item-text-container {
  margin-left: 10px;
}

.chat-list-item h3, .chat-list-item h4 {
  text-transform: none;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  word-spacing: 0;
}

.chat-list-item h4 {
  font-weight: 300;
  font-size: 90%;
  width: 200px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-box, .hide-chat-box {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  bottom: 10px;
  left: 4%;
  width: 300px;
  height: 500px;
  border-radius: 10px;
  background-color: #FFF;
  border: 1px solid #e9e9e9;
  transition: bottom 0.3s ease-in-out;
  z-index: 30;
}

.hide-chat-box {
  bottom: -510px;
  transition: bottom 0.3s ease-in-out;
}

.chat-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-box label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 60%;
  margin-left: 10px;
  margin-bottom: 5px;
}

.chat-box-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
}

.chat-box-header-container img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.chat-box-header-container h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 0;
}

.chat-header-hide-button {
  margin-left: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-box-message-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
}

.chat-box-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9e9e9;
  padding: 10px 10px 5px 10px;
}

.chat-box-input-container textarea {
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  width: 80%;
  height: 40px;
  resize: none;
  border: 1px solid #e9e9e9;
  border-radius: 5px;

}

.chat-box-input-container textarea:focus-visible {
  outline: none;
}

@media (max-width: 1023px) {
  .show-chat-list, .hide-chat-list  {
    top: 0px;
    left: -300px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; 
  }

  .hide-chat-list {
    top: -490px;
  }
}

@media (max-width: 480px) {
  .show-chat-list, .hide-chat-list  {
    width: 220px;
    left: -220px;
  }

  .chat-list-item h3, .chat-list-item h4 {
    font-size: 100%;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-list-item h4 {
    font-size: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 320px) {
  .show-chat-list, .hide-chat-list  {
    width: 190px;
    left: -190px;
  }

  .chat-list-item h3, .chat-list-item h4 {
    font-size: 80%;
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-list-item h4 {
    font-size: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.video-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #43cea2; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #43cea2, #185a9d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.videos {
  background-color: #000;
  display: flex;
  flex-direction: row;
}

.logo-container {
  position: absolute;
  left:30px;
  top:30px;
  z-index: 20;
}

.logo-container img {
  height: 50px;
}

.right-content-container, .show-right-content-container {
  width: 25%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #e9e9e9;
  z-index: 20;
}

.timer, .show-timer {
  margin: 15px 0px 0 0;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 10%;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  //padding: 10px 20px;
  border-radius: 10px;
}

.timer-contents-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 90%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 10px;
}

.show-chat-button {
  display: none;
}

.chat-container, .show-chat-container {
  margin: 10px 0px 0 0;
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat-message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  background-color: #FFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: scroll;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
}

.last-chat-item {
  height: 100px;
  width: 100%;
  //background-color: pink;
}

.chat-message, .my-chat-message {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 5px;
}

.my-chat-message {
  align-items: flex-end;
  flex-direction:
}

.chat-message-name {
  margin-left: 40px;
  margin-bottom: 2px;
}

.chat-message img, .empty-profilePic {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.chat-message label {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 70%;
}

.chat-profil-pic-and-text-container, .my-chat-content {
  display: flex;
  flex-direction: row;
}

.my-chat-content {
  flex-direction: row-reverse;
}

.chat-message-text-container {
  display: flex;
  flex-direction: column;
  background-color: #e9e9e9;
  margin-left: 10px;
  justify-content: center;
  border-radius: 10px;
  border-top-left-radius: 0;
  height: auto;
  max-width: 70%;
  padding: 5px 0;
  word-wrap: break-word;
}

.chat-message-text-container label {
  margin: 0 10px;
  word-break: break-word;
}

.my-text-container {
  background-color: #599cc9;
  margin-right: 10px;
  margin-left: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  max-width: 70%;
}

.my-text-container label {
  color: #FFF;
  word-break: break-word;
}

.chat-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #FFF;
  padding: 5px 5px 0 5px;
  border-top: 2px solid #e9e9e9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input label {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 50%;
  margin-top: 5px;
}

.chat-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.send-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--mainThemeColour);
}

.send-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.chat-container input, .show-chat-container input, .chat-container textarea, .show-chat-container textarea {
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  width: 80%;
  height: 40px;
  resize: none;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 5px;
}

.chat-container input:focus-visible, .show-chat-container input:focus-visible, .show-chat-container textarea:focus-visible {
  outline: none;
}

.chat-container input:focus, .show-chat-container input:focus {
  outline: none;
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  //background-color: pink;
}

.empty-chat-container h3, .empty-chat-container h4 {
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  font-size: 75%;
  font-weight: 300;
  border: 1px solid var(--fontColour);
  padding: 2px 10px;
  border-radius: 20px;
}

.empty-chat-container h4 {
  background-color: var(--mainThemeColour);
  color: #FFF;
  border: 1px solid var(--mainThemeColour);
  border-radius: 10px;
  padding: 10px 10px;
  font-weight: 500;
}

.profilePic-clock-container label, .profilePic-clock-container h4, .count-down label {
  color: var(--subheadingGrey);
  font-size: 90%;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.profilePic-clock-container h4, .count-down label {
  color: var(--fontColour);
  font-weight: 600;
}

.profilePic-clock-container{
 display: flex;
 flex-direction: row;
 height: auto;
}

.profilePic-clock-container img, .count-down {
  width: 60px;
  height: 60px;
}

.profilePic-clock-container img {
  border-radius: 10px;
  margin-right: 10px;
}

.clock-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.clock-container h4 {
  margin-top: 0;
}

.count-down {
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  z-index: 10;
}

.redTimer{
  border: 2px solid red;
}

.redTimer label { color: red;}

.remote-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 75%;
  background-color: black;
  position: relative;
}

.remote-video video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.my-video, .lower-my-video {
  position: absolute;
  bottom: 0%;
  right: 0;
  width: 200px;
  height:200px;
  z-index: 15;
}

.my-video video, .lower-my-video video {
  object-fit: cover;
}

.video-buttons, .hide-video-buttons, .green-video-buttons {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 30px;
  //bottom: 5%;
  //width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-buttons button, .hide-video-buttons button {
  margin: 20px;
  padding: 10px 30px;
  width: 200px;
}

.video-buttons img, .hide-video-buttons img {
  margin: 20px;
  height: 50px;
  width: 50px;
}

.green-video-buttons {
  position: absolute;
  flex-direction: row;
  left: 0;
  bottom: 5%;
  width: 100%;
  height: auto;
}

.waiting-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  background-color: #000;
}

.waiting-text h2 {
  color: #FFF;
  text-align: center;
}

.video-call-error-button-container, .hide-error-button  {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 80%;
}

.video-call-error-button-container button, .hide-error-button button {
  background-color: #D14335;
  border-color: #D14335;
  border-radius: 12px;
}

.video-call-error-button-container button:hover, .hide-error-button button:hover {
  background-color: rgba(0,0,0,0);
  color: #D14335;
}

.error-log-input {
  //background-color: pink;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-log-input textarea {
  margin: 5px 0px;
  width: 97%;
}

