.landing-page-hero-section {
  background-color: #000;
  width: 1440px;
  height: 744px;
  /* background-image: url(../Images/LandingPage/HeroHeader.jpeg);
  background-position-x: 50%;
  background-position-y: 80%;
  background-size: 130%; */
  display: flex;
  flex-direction: column;
  //align-items: center;
  position: relative;
  justify-content: flex-end;
  background-color: #000;
  overflow-x: hidden;
}

.landing-page-hero-background-image {
  /* background-position-x: 50%;
  background-position-y: 80%;
  background-size: 130%; */
  object-position: center;
  object-fit: cover;
  width: 1440px;
  height: 744px;
  position: absolute;
  top:0;
  left: 0;
  z-index: 0;
}

.landing-page-hero-section-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  //padding-left: 70px;
  //margin-top: 127px;
  z-index: 5;
}

.landing-page-hero-section-button-container img,
.lish-logo-image {
  width: 158px;
  height: 104px;
}

.landing-page-hero-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page-hero-section-button-container  button {
  /* width: 158px;
  height: 40px; */
  /* width: 516.3px;
  height: 109px; */
  width: 350px;
  height: 60px;
  margin-top: 10px;
  padding: 0;
  /* font-size: 20px;
  font-style: normal;
  font-weight: 500; */
  font-family: "Mplus 1p-Medium";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 23px;
  letter-spacing: 0.08em;
}

.landing-page-hero-section-text-container {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 251px;
  z-index: 1;
}

.landing-page-hero-section h2 {
  color: #FFF;
  font-family: "Mplus 1p-Medium";
  font-size: 56.43px;
}

@media (max-width: 1440px) {
  .landing-page-hero-section {
    width: 1280px;
    background-position-x: 50%;
    background-position-y: 80%;
    background-size: 130%;
  }

  .landing-page-hero-background-image {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .landing-page-hero-section {
    width: 1024px;
    height: 598px;
    background-position-x: 55%;
    background-position-y: 70%;
    background-size: 145%;
  }

  .landing-page-hero-background-image {
    width: 1024px;
    height: 598px;
  }

  .landing-page-hero-section-button-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .landing-page-hero-buttons {
    margin-left: 15px;
    margin-top: -10px;
  }

  .landing-page-hero-buttons button {
    width: 207px;
    height: 60px;
    font-size: 18px;
  }

  .landing-page-hero-section-text-container {
    height: 122px;
    //margin-top: 191px;
  }

  .landing-page-hero-section h2 {
    font-size: 45.14px;
  }
}

@media (max-width: 1023px) {
  .landing-page-hero-section {
    width: 1023px;
    height: 598px;
    background-position-x: 60%;
    background-size: 120%;
  }

  .landing-page-hero-background-image {
    width: 1023px;
    height: 598px;
  }

  .landing-page-hero-section-button-container {
    margin-top: 64px;
  }

  .landing-page-hero-section-text-container {
    height: 99px;
    //margin-top: 305px;
  }

  .landing-page-hero-section h2 {
    font-size: 35px;
  }
}

@media (max-width: 786px) {
  .landing-page-hero-section {
    width: 786px;
    height: 427px;
    background-position:center;
    background-size: contain;
    //position: relative;
  }

  .landing-page-hero-background-image {
    width: 786px;
    height: 427px;
  }

  .landing-page-hero-section-button-container {
    /* margin-top: 64px;
    flex-direction: column; */
    margin-bottom: 50px;
  }

  .landing-page-hero-buttons {
    flex-direction: row;
    margin-top: 140px;
    margin-left: 0;
  }

  .landing-page-hero-section-button-container  img {
    width: 130px;
    height: 86px;
  }

  .landing-page-hero-section-button-container  button {
    width: 174.42px;
    height: 50.56px;
    font-size: 15.1667px;
    margin: 15px;
    z-index: 10;
  }

  .landing-page-hero-section-text-container {
    height: 151px;
    margin-top: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    align-items: flex-end;
    //padding-bottom: 30px;
    position: absolute;
    bottom: 0;
  }

  .landing-page-hero-section h2 {
    font-size: 19px;
  }
}

@media (max-width: 428px) {
  .landing-page-hero-section {
    width: 428px;
    height: 364px;
    background-position:center;
    background-size: contain;
  }

  .landing-page-hero-background-image {
    width: 428px;
    height: 364px;
  }

  .landing-page-hero-section-button-container {
    margin-top: 50px;
  }

  .landing-page-hero-section-button-container  img,
  .lish-logo-image {
    width: 101px;
    height: 67px;
  }

  .landing-page-hero-buttons {
    flex-direction: column;
    margin-top: 90px;
    margin-left: 0;
  }

  .landing-page-hero-section-button-container  button {
    width: 155.94px;
    height: 45.2px;
    font-size: 13.5603px;
    margin: 5px;
  }

  .landing-page-hero-section-text-container {
    height: 135px;
    padding-bottom: 15px;
  }

  .landing-page-hero-section h2 {
    font-size: 15px !important;
  }
}
