.show-chat-list, .hide-chat-list {
  position: absolute;
  top: 100px;
  //left: 0%;
  width: 300px;
  max-height: 400px;
  border-radius: 5px;
  //background-color: rgba(0,0,0,0.6);
  background-color: rgba(255,255,255,1);
  //background-color: #FFF;
  transition: top 0.3s ease-in-out;
  overflow: scroll;
  overflow-x: hidden;
}

.hide-chat-list {
  top: -600px;
  transition: top 0.3s ease-in-out;
}

.empty-chat-list {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
}

.empty-chat-list label {
  margin: 10px 0;
}

.chat-icon {
  font-size: 350%;
}

.chat-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin: 20px 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-list-item img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.chat-list-item-text-container {
  margin-left: 10px;
}

.chat-list-item h3, .chat-list-item h4 {
  text-transform: none;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  word-spacing: 0;
}

.chat-list-item h4 {
  font-weight: 300;
  font-size: 90%;
  width: 200px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-box, .hide-chat-box {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  bottom: 10px;
  left: 4%;
  width: 300px;
  height: 500px;
  border-radius: 10px;
  background-color: #FFF;
  border: 1px solid #e9e9e9;
  transition: bottom 0.3s ease-in-out;
  z-index: 30;
}

.hide-chat-box {
  bottom: -510px;
  transition: bottom 0.3s ease-in-out;
}

.chat-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-box label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 60%;
  margin-left: 10px;
  margin-bottom: 5px;
}

.chat-box-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
}

.chat-box-header-container img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.chat-box-header-container h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 0;
}

.chat-header-hide-button {
  margin-left: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-box-message-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  overflow-x: hidden;
}

.chat-box-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9e9e9;
  padding: 10px 10px 5px 10px;
}

.chat-box-input-container textarea {
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: var(--fontColour);
  width: 80%;
  height: 40px;
  resize: none;
  border: 1px solid #e9e9e9;
  border-radius: 5px;

}

.chat-box-input-container textarea:focus-visible {
  outline: none;
}

@media (max-width: 1023px) {
  .show-chat-list, .hide-chat-list  {
    top: 0px;
    left: -300px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; 
  }

  .hide-chat-list {
    top: -490px;
  }
}

@media (max-width: 480px) {
  .show-chat-list, .hide-chat-list  {
    width: 220px;
    left: -220px;
  }

  .chat-list-item h3, .chat-list-item h4 {
    font-size: 100%;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-list-item h4 {
    font-size: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 320px) {
  .show-chat-list, .hide-chat-list  {
    width: 190px;
    left: -190px;
  }

  .chat-list-item h3, .chat-list-item h4 {
    font-size: 80%;
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-list-item h4 {
    font-size: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
